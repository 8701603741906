/* Responsive CSS Sprite created using: https://responsive-css.us/ */
.sprite-icon-md {
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-md-v1.5.png');
}
.sp-ic-md-avgl_logo {
  width: 180px;
  height: 60px;
  background-position: 39.7590% 1.02041%;
  background-size: 284.444%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-md-v1.5.png');
}
.sp-ic-md-avgl_logo_dark {
  width: 180px;
  height: 60px;
  background-position: 94.5783% 1.02041%;
  background-size: 284.444%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-md-v1.5.png');
}
.sp-ic-md-empty_notification {
  width: 128px;
  height: 128px;
  background-position: 0.520833% 1.56250%;
  background-size: 400.000%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-md-v1.5.png');
}
.sp-ic-md-logo-v2 {
  width: 256px;
  height: 38px;
  background-position: 0.781250% 60.5505%;
  background-size: 200.000%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-md-v1.5.png');
}
.sp-ic-md-logo-v2-dark {
  width: 268px;
  height: 40px;
  background-position: 54.0984% 29.6296%;
  background-size: 191.045%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-md-v1.5.png');
}

.sp-ic-md-logo-v2-theme {
  width: var(--width-logo-icon);
  height: var(--height-logo-icon);
  background-position: var(--bg-position-logo-icon);
  background-size: var(--bg-size-logo-icon);
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-md-v1.5.png');
}
.sp-ic-md-avgl_logo-theme {
  width: var(--avgl-width-logo-icon);
  height: var(--avgl-height-logo-icon);
  background-position: var(--avgl-bg-position-logo-icon);
  background-size: var(--avgl-bg-size-logo-icon);
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-md-v1.5.png');
}
