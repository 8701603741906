/*
Responsive CSS Sprite created using: https://responsive-css.us/
*/

.sprite-icon-sm {
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-arrow {
  width: 8px;
  height: 7px;
  background-position: 98.4127% 57.8313%;
  background-size: 6400.00%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-arrow-bottom {
  width: 34px;
  height: 22px;
  background-position: 82.2176% 85.0427%; 
  background-size: 1505.88%; 
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-avgl_default_profile {
  width: 48px;
  height: 48px;
  background-position: 19.3966% 29.8077%;
  background-size: 1066.67%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-bet-close {
  width: 13px;
  height: 13px;
  background-position: 99.3988% 53.0864%;
  background-size: 3938.46%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-BoomBucs-icon {
  width: 33px;
  height: 33px;
  background-position: 40.0835% 72.1973%;
  background-size: 1551.52%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-boom_icon_orange {
  width: 64px;
  height: 30px;
  background-position: 73.4375% 71.2389%;
  background-size: 800.000%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-cake {
  width: 50px;
  height: 50px;
  background-position: 40.2597% 0.970874%;
  background-size: 1024.00%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-cake_remain_item {
  width: 20px;
  height: 20px;
  background-position: 99.3902% 84.3220%;
  background-size: 2560.00%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-cal {
  width: 23px;
  height: 23px;
  background-position: 65.6442% 85.4077%;
  background-size: 2226.09%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-checkmark {
  width: 16px;
  height: 13px;
  background-position: 46.7742% 94.2387%;
  background-size: 3200.00%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-crown-n1 {
  width: 12px;
  height: 9px;
  background-position: 99.0000% 70.0405%;
  background-size: 4266.67%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-Discord-Logo {
  width: 21px;
  height: 23px;
  background-position: 70.4684% 85.4077%;
  background-size: 2438.10%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-Discord-Logo-White {
  width: 40px;
  height: 39px;
  background-position: 87.7119% 52.0737%;
  background-size: 1280.00%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-Discord-Logo_Grey {
  width: 27px;
  height: 27px;
  background-position: 13.6082% 86.8996%;
  background-size: 1896.30%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-dropdown-arrow {
  width: 10px;
  height: 6px;
  background-position: 98.6056% 73.6000%;
  background-size: 5120.00%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-dropdown-arrow-up {
  width: 10px;
  height: 6px;
  background-position: 49.8008% 91.6000%;
  background-size: 5120.00%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-drops-arrow {
  width: 10px;
  height: 10px;
  background-position: 98.6056% 65.4472%;
  background-size: 5120.00%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-drops-arrows {
  width: 28px;
  height: 31px;
  background-position: 46.9008% 71.5556%;
  background-size: 1828.57%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-drops-arrows-boom {
  width: 28px;
  height: 31px;
  background-position: 53.0992% 71.5556%;
  background-size: 1828.57%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-drops-clock {
  width: 35px;
  height: 42px;
  background-position: 35.2201% 52.8037%;
  background-size: 1462.86%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-drops-clock-boom {
  width: 35px;
  height: 42px;
  background-position: 42.9769% 52.8037%;
  background-size: 1462.86%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-drops-crown {
  width: 21px;
  height: 14px;
  background-position: 42.5662% 94.6281%;
  background-size: 2438.10%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-drops-crown-ava {
  width: 30px;
  height: 30px;
  background-position: 81.9502% 71.2389%;
  background-size: 1706.67%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-drops-more {
  width: 24px;
  height: 24px;
  background-position: 19.4672% 85.7759%;
  background-size: 2133.33%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-drops-more-boom {
  width: 24px;
  height: 24px;
  background-position: 24.7951% 85.7759%;
  background-size: 2133.33%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-drops-win {
  width: 86px;
  height: 48px;
  background-position: 32.8638% 29.8077%;
  background-size: 595.349%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-drops-win-boom {
  width: 86px;
  height: 48px;
  background-position: 53.5211% 29.8077%;
  background-size: 595.349%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-drops-win-boom-light-theme {
  width: 86px;
  height: 49px;
  background-position: 0.469484% 29.9517%;
  background-size: 595.349%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-event-card-close {
  width: 24px;
  height: 24px;
  background-position: 30.1230% 85.7759%;
  background-size: 2133.33%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-event-card-crown {
  width: 32px;
  height: 22px;
  background-position: 89.3750% 85.0427%;
  background-size: 1600.00%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-event-card-host {
  width: 28px;
  height: 30px;
  background-position: 88.2231% 71.2389%;
  background-size: 1828.57%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-event-card-info {
  width: 40px;
  height: 40px;
  background-position: 59.7458% 52.3148%;
  background-size: 1280.00%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-event-card-pc {
  width: 24px;
  height: 21px;
  background-position: 94.8770% 84.6809%;
  background-size: 2133.33%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-event-card-pc-black {
  width: 24px;
  height: 21px;
  background-position: 0.409836% 97.4468%;
  background-size: 2133.33%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-event-card-ps {
  width: 32px;
  height: 24px;
  background-position: 36.0417% 85.7759%;
  background-size: 1600.00%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-event-card-ps-black {
  width: 32px;
  height: 24px;
  background-position: 43.1250% 85.7759%;
  background-size: 1600.00%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-event-card-status {
  width: 30px;
  height: 28px;
  background-position: 0.414938% 87.2807%;
  background-size: 1706.67%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-event-card-xbox {
  width: 25px;
  height: 24px;
  background-position: 49.4867% 85.7759%;
  background-size: 2048.00%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-event-card-xbox-black {
  width: 25px;
  height: 24px;
  background-position: 55.0308% 85.7759%;
  background-size: 2048.00%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-event-edit {
  width: 36px;
  height: 35px;
  background-position: 25.6303% 72.8507%;
  background-size: 1422.22%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-events-icon {
  width: 48px;
  height: 46px;
  background-position: 0.431034% 53.8095%;
  background-size: 1066.67%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-fb-2 {
  width: 36px;
  height: 36px;
  background-position: 0.420168% 73.1818%;
  background-size: 1422.22%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-if_twitter_317720 {
  width: 48px;
  height: 48px;
  background-position: 68.1034% 29.8077%;
  background-size: 1066.67%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-in {
  width: 36px;
  height: 36px;
  background-position: 8.40336% 73.1818%;
  background-size: 1422.22%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-land-creator-icon {
  width: 56px;
  height: 45px;
  background-position: 11.4035% 53.5545%;
  background-size: 914.286%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-land-highschool-icon {
  width: 56px;
  height: 56px;
  background-position: 15.3509% 1.00000%;
  background-size: 914.286%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-land-hub-icon {
  width: 56px;
  height: 45px;
  background-position: 24.1228% 53.5545%;
  background-size: 914.286%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-land-official-icon {
  width: 56px;
  height: 56px;
  background-position: 28.0702% 1.00000%;
  background-size: 914.286%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-left-arrow {
  width: 32px;
  height: 58px;
  background-position: 0.416667% 1.01010%;
  background-size: 1600.00%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-live-2 {
  width: 18px;
  height: 18px;
  background-position: 9.71660% 96.2185%;
  background-size: 2844.44%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-live-3 {
  width: 21px;
  height: 18px;
  background-position: 13.8493% 96.2185%;
  background-size: 2438.10%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-live-4 {
  width: 18px;
  height: 20px;
  background-position: 5.66802% 97.0339%;
  background-size: 2844.44%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-lock {
  width: 16px;
  height: 16px;
  background-position: 30.6452% 95.4167%;
  background-size: 3200.00%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-mixer_icon_v2 {
  width: 48px;
  height: 47px;
  background-position: 89.6552% 29.6651%;
  background-size: 1066.67%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-modal-close {
  width: 24px;
  height: 24px;
  background-position: var(--popup-close-position);
  background-size: 2133.33%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.4.png');
}

.sp-ic-sm-modal-mixer {
  width: 30px;
  height: 28px;
  background-position: 7.05394% 87.2807%;
  background-size: 1706.67%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-modal-twitch {
  width: 30px;
  height: 34px;
  background-position: 33.1950% 72.5225%;
  background-size: 1706.67%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-notification_bell {
  width: 50px;
  height: 50px;
  background-position: 51.5152% 0.970874%;
  background-size: 1024.00%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-notification_red_bell {
  width: 50px;
  height: 50px;
  background-position: 62.7706% 0.970874%;
  background-size: 1024.00%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-onclick_notification_bell {
  width: 50px;
  height: 50px;
  background-position: 74.0260% 0.970874%;
  background-size: 1024.00%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-playersicon {
  width: 12px;
  height: 14px;
  background-position: 62.7706% 0.970874%;
  background-size: 4266.67%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-playersicon-blue1 {
  width: 12px;
  height: 14px;
  background-position: 99.6000% 0.826446%;
  background-size: 4266.67%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-playersicon-orange {
  width: 12px;
  height: 14px;
  background-position: 99.6000% 14.0496%;
  background-size: 4266.67%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-playersicon-red {
  width: 12px;
  height: 14px;
  background-position: 99.2000% 46.6942%;
  background-size: 4266.67%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-red_liked {
  width: 46px;
  height: 40px;
  background-position: 69.5279% 52.3148%;
  background-size: 1113.04%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-right-arrow {
  width: 32px;
  height: 57px;
  background-position: 7.50000% 1.00503%;
  background-size: 1600.00%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-RulesIcon {
  width: 18px;
  height: 18px;
  background-position: 18.4211% 96.2185%;
  background-size: 2844.44%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-RulesIconRed {
  width: 18px;
  height: 18px;
  background-position: 22.4696% 96.2185%;
  background-size: 2844.44%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-share {
  width: 19px;
  height: 17px;
  background-position: 26.5720% 95.8159%;
  background-size: 2694.74%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-shop {
  width: 17px;
  height: 15px;
  background-position: 34.3434% 95.0207%;
  background-size: 3011.76%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-twitch {
  width: 22px;
  height: 23px;
  background-position: 75.3061% 85.4077%;
  background-size: 2327.27%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-twitch-icon {
  width: 40px;
  height: 40px;
  background-position: 78.8136% 52.3148%;
  background-size: 1280.00%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-twitch-profile {
  width: 38px;
  height: 42px;
  background-position: 51.0549% 52.8037%;
  background-size: 1347.37%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-twitch_icon {
  width: 50px;
  height: 50px;
  background-position:  85.2814% 0.970874%;
  background-size: 1024.00%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-twitter {
  width: 18px;
  height: 15px;
  background-position: 38.2591% 95.0207%;
  background-size: 2844.44%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-twitter-2 {
  width: 42px;
  height: 36px;
  background-position: 16.5957% 73.1818%;
  background-size: 1219.05%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-twittericon {
  width: 36px;
  height: 30px;
  background-position: 96.0084% 71.2389%;
  background-size: 1422.22%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-user-unknown {
  width: 38px;
  height: 38px;
  background-position: 96.2025% 51.8349%;
  background-size: 1347.37%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-videos {
  width: 44px;
  height: 44px;
  background-position: 99.5726% 29.2453%;
  background-size: 1163.64%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-view {
  width: 40px;
  height: 31px;
  background-position: 60.8051% 71.5556%;
  background-size: 1280.00%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-youtube2 {
  width: 50px;
  height: 50px;
  background-position: 96.5368% 0.970874%;
  background-size: 1024.00%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-sm-youtube_icon_v2 {
  width: 48px;
  height: 48px;
  background-position: 78.8793% 29.8077%;
  background-size: 1066.67%;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/spritesheet-icon-sm-v2.5.png');
}

.sp-ic-instagram-icon {
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/icons8-instagram2.png');
  background-size: 110% 110%;
  background-position: 40% 60%;
}

.sp-ic-tiktok-icon {
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url('../../assets/spritesheet/icons8-tiktok-50.png');
  background-size: 110% 110%;
  background-position: 40% 60%;
}

.sp-ic-sm-event-card-info-20x20 {
  width: 20px;
  height: 20px;
}

.sp-ic-sm-event-card-close {
  width: 12px;
  height: 12px;
}

.sp-ic-sm-modal-close-14x14 {
  width: 14px;
  height: 14px;
}

.sp-ic-sm-modal-close-10x10 {
  width: 10px;
  height: 10px;
}

.slick-prev:before {
  @extend .sprite-icon-sm;
  @extend .sp-ic-sm-left-arrow;
}

.slick-next:before {
  @extend .sprite-icon-sm;
  @extend .sp-ic-sm-right-arrow;
}
